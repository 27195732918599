import { Button as ButtonPrime } from 'primereact/button'
import PropTypes from 'prop-types'

export const Button = ({
  label,
  type,
  icon,
  iconPos,
  onClick,
  severity,
  raised,
  rounded,
  text,
  outlined,
  disabled,
  className,
  loading
}) => {
  return (
    <ButtonPrime
      label={label}
      type={type}
      icon={icon}
      iconPos={iconPos}
      onClick={onClick}
      severity={severity}
      raised={raised}
      rounded={rounded}
      text={text}
      outlined={outlined}
      disabled={disabled}
      className={className}
      loading={loading}
    />

  )
}

Button.propTypes = {
  type: PropTypes.string,
  loading: false
}
