import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Modal } from '@/components/moleculas/Modal'
import { FormHookProvider } from '@/components/moleculas'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { InputText } from '@/components/moleculas/InputText'
// import { InputPassword } from '@/components/moleculas/InputPassword'
import { Button } from '@/components/atoms'
import { useForm } from 'react-hook-form'
import PaymentMethodEngine from '@/components/moleculas/PaymentMethodEngine/PaymentMethodEngine'
import { payMemberAsync, saveCardAsync } from '@/services/axios/authenticated/account/paymentMethod'
// import { useRouter } from 'next/router'
import Btn from '@/components/atoms/Btn/Btn'
import { AuthContext } from '@/context/AuthContext'
import ModalSpeiMethod from '../ModalSpeiMethod/ModalSpeiMethod'
import ModalOxxoReference from '../ModalOxxoMethod/ModalOxxoMethod'

export default function ModalPaymentMethod ({
  visible,
  onHide,
  // onClickPay,
  membership,
  selectedPrice,
  promotionId,
  setShowPaymentProcessing,
  setShowPaymentAccepted,
  setShowPaymentDeclined,
}) {
  // const router = useRouter()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0)
  const [order, setOrder] = useState(false)

  // const [cardNumber, setCardNumber] = useState()
  // const [cvv, setCvv] = useState()

  const { onSubmit, onOxxo, onSpei, responseLogOx, responseLogSp } = useContext(AuthContext)

  const handleChange = (e) => {
    if (e.index != null) setSelectedPaymentMethod(e.index)
  }

  // const onSubmit = (data) => {
  //   onClickPay()
  // }

  const methods = useForm()

  async function paymentCard (tokenId) {
    try {
      const formData = {
        card: tokenId,
        promotion: promotionId,
        type: 1
      }
      return await payMemberAsync(formData, membership)
    } catch (e) {
      // sweetError('', e)
    }
  }

  async function saveCard (tokenId) {
    try {
      const formData = {
        token: tokenId
      }
      return await saveCardAsync(formData)
    } catch (e) {
      // sweetError('', e)
    }
  }

  const savedCard = (token) => {
    setShowPaymentProcessing(true)
    saveCard(token.id).then((res) => {
      setShowPaymentProcessing(false)
      setShowPaymentAccepted(true)
      onSubmit.onPayMembershipSubmit(paymentCard, res, setShowPaymentAccepted)
      // paymentCard(res.data.record.id).then((response) => {
      //   onSubmit.onFreeMembershipSubmit(membership.id)
      //   // setShowPaymentAccepted(false)
      //   router.push('/system/dashboard')
      // }).finally(() => {
      //   setShowPaymentAccepted(false)
      //   return 0
      // })
      // }, 500)
    }).catch(() => {
      setShowPaymentDeclined(true)
    })
  }

  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')

    script.setAttribute('src', 'https://pay.conekta.com/v1.0/js/conekta-checkout.min.js')
    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [])

  async function paymentOxxo () {
    try {
      // console.log('order', order);
      const formData = {
        order: order.id,
        promotion: promotionId,
        type: 2
      }
      // console.log('formData', formData);
      onSubmit.onMembershipSubmit(payMemberAsync, formData, membership, setShowPaymentAccepted)
      // return await payMemberAsync(formData, membership)
    } catch (e) {
      // sweetError('', e)
    }
  }
  async function paymentSpei () {
    try {
      // console.log('order', order);
      const formData = {
        order: order.id,
        promotion: promotionId,
        type: 3
      }
      // console.log('formData', formData);
      onSubmit.onMembershipSubmit(payMemberAsync, formData, membership, setShowPaymentAccepted)
      // return await payMemberAsync(formData, membership)
    } catch (e) {
      // sweetError('', e)
    }
  }
  useEffect(() => {
    setOrder(false)
    try {
      const formData = {
        // card: tokenId,
        promotion: promotionId
      }
      switch (selectedPaymentMethod) {
        case 1:
          onOxxo(formData, membership, setOrder)
          break
        case 2:
          onSpei(formData, membership, setOrder)
          break
        default:
          break
      }
      // return await payMemberAsync(formData, membership)
    } catch (e) {
      // sweetError('', e)
    }
  }, [selectedPaymentMethod])

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      width='580px'
      dismissableMask
    >
      <div className={styles.payment_header}>
        Elegir método de pago
      </div>
      <div className={styles.payment_container} style={{ height: 'bodyHeight' }}>
        <FormHookProvider method='POST' methods={methods}>
          <div className={styles.payment_total}>
            <div>Total:</div>
            <div>{selectedPrice.total}</div>
          </div>
          <div className={styles.payment_card}>
            <table>
              <thead>
                <tr><th>Producto</th><th>Monto</th></tr>
              </thead>
              <tbody>
                <tr><td>Membresía</td><td>{selectedPrice.unitPrice}</td></tr>
              </tbody>
            </table>
          </div>
          <Accordion
            activeIndex={selectedPaymentMethod}
            className={styles.payment_section}
            onTabChange={(e) => handleChange(e)}
          >
            <AccordionTab
              header={
                <div className={styles.payment_section_header}>
                  <div>
                    <i className='icon i-creditcard' />
                    <span>Credito / Debito</span>
                  </div>
                  <div>
                    <span className={`${styles.payment_radio} ${selectedPaymentMethod === 0 && styles.payment_radio_active}`} />
                  </div>
                </div>
              }
            >
              <PaymentMethodEngine onSucced={savedCard} />
            </AccordionTab>
            <AccordionTab
              header={
                <div className={styles.payment_section_header}>
                  <div>
                    <i className='icon i-oxxo' style={{ width: '44px', height: '20px' }} />
                    <span>Oxxo pago</span>
                  </div>
                  <div>
                    <span className={`${styles.payment_radio} ${selectedPaymentMethod === 1 && styles.payment_radio_active}`} />
                  </div>
                </div>
              }
            >
              <div className={styles.payment_section_content}>
                <div>Referencia: {order?.reference}</div>
                <div>Paga antes de: {order?.limit_date}</div>
                <Btn className='btnBlueRoundedBig' type={'button'} disabled={!order} onClick={()=>{paymentOxxo()}}>Continuar</Btn>
              </div>
            </AccordionTab>
            <AccordionTab
              header={
                <div className={styles.payment_section_header}>
                  <div>
                    <i className='icon i-creditcard' />
                    <span>Spei (Transferencia)</span>
                  </div>
                  <div>
                    <span className={`${styles.payment_radio} ${selectedPaymentMethod === 2 && styles.payment_radio_active}`} />
                  </div>
                </div>
              }
            >
              <div className={styles.payment_section_content}>
                <div>Banco: {order?.bank}</div>
                <div>CLABE: {order?.clabe}</div>
                <div>Paga antes de: {order?.limit_date}</div>
                <Btn className='btnBlueRoundedBig' type={'button'} disabled={!order} onClick={()=>{paymentSpei()}}>Continuar</Btn>
              </div>
            </AccordionTab>
          </Accordion>
          <ModalOxxoReference
            visible={responseLogOx}
            onAllReadySubmit={onSubmit.onAllReadySubmit}
            selectedPrice={selectedPrice?.total}
            order={order}
          />
          <ModalSpeiMethod
            visible={responseLogSp}
            onAllReadySubmit={onSubmit.onAllReadySubmit}
            selectedPrice={selectedPrice?.total}
            order={order}
          />
        </FormHookProvider>
      </div>
    </Modal>
  )
}
