import { useForm } from 'react-hook-form'
import { useState } from 'react'
import styles from './styles.module.scss'
import { Button } from '@/components/atoms'
import { Modal } from '@/components/moleculas/Modal'

export default function ModalSelectPlan ({
  type,
  monthlyPrice,
  annualPrice,
  visible,
  onHide,
  onClickPay,
  setSelectedPrice
}) {
  const { register, handleSubmit } = useForm()
  const [selectedOption, setSelectedOption] = useState('')
  const onSubmit = (data) => {
    if (data.options === monthlyPrice) {
      setSelectedPrice({
        promotion: 0,
        total: monthlyPrice,
        unitPrice: monthlyPrice
      })
    } else {
      setSelectedPrice({
        promotion: 1,
        total: annualPrice,
        unitPrice: monthlyPrice
      })
    }
    onClickPay()
  }

  const handleChange = (e) => {
    const value = e.target.value
    setSelectedOption(value)
  }

  return (
    <Modal
      visible={visible}
      onHide={onHide}
      width='580px'
      dismissableMask
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.form_header}>
          <div>{type}</div>
        </div>
        <div className={styles.form_body}>
          <div className={styles.card}>
            <label
              className={`${styles.form_option} ${selectedOption === monthlyPrice && styles.selected}`}
            >
              <input
                type='radio'
                name='options'
                value={monthlyPrice}
                {...register('options', { required: true, onChange: (e) => handleChange(e) })}
              />
              <div>Mensual</div>
              <div className={styles.card_body}>
                <div>{/* $ */}</div>
                <div>{monthlyPrice}</div>
                <div>/MXN</div>
              </div>
            </label>
          </div>
          <div className={styles.card}>
            <label
              className={`${styles.form_option} ${selectedOption === annualPrice && styles.selected}`}
            >
              <input
                type='radio'
                name='options'
                value={annualPrice}
                {...register('options', { required: true, onChange: (e) => handleChange(e) })}
              />
              <div>Anual</div>
              <div className={styles.card_body}>
                <div>{/* $ */}</div>
                <div>{annualPrice}</div>
                <div>/MXN</div>
              </div>
            </label>
          </div>
        </div>
        <div className={styles.form_footer}>
          <Button
            label='Pagar'
            type='submit'
            rounded
            className='btn-yellow btn-xl'
          />
        </div>
      </form>
    </Modal>
  )
}
