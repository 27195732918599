import { Dialog } from '@/components/atoms'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'

export const Modal = ({
  visible,
  onHide,
  dismissableMask,
  header,
  footer,
  width,
  className,
  children
}) => {
  return (
    <div className={`${styles.modal} ${className}`}>
      <Dialog
        visible={visible}
        onHide={onHide}
        dismissableMask={dismissableMask}
        closable={false}
        header={header}
        footer={footer}
        style={{ width }}
        breakpoints={{ '768px': '90vw' }}
      >
        {children}
      </Dialog>
    </div>
  )
}

Modal.propTypes = {
  label: PropTypes.string
}
