import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Modal } from '@/components/moleculas/Modal'
import Btn from '@/components/atoms/Btn/Btn'

export default function ModalSpeiMethod ({
  visible,
  onAllReadySubmit,
  selectedPrice,
  order,
  onHide = false
}) {
  // console.log('asd1f',visible,
  //   onAllReadySubmit,
  //   selectedPrice,
  //   order,
  //   onHide, !!visible);
  return (
    <Modal
      visible={!!visible}
      onHide={onHide}
      width='580px'
      dismissableMask
    >
      <div className={styles.payment_header}>
        Referencia de pago
      </div>
      <div className={styles.payment_container} style={{ height: 'bodyHeight' }}>
        <div>¡Todo esta listo para pagar!</div>
        <div className={`${styles.color212247} ${styles.fs18px}`}>Banco: {order?.bank}</div>
        <div className={`${styles.color212247} ${styles.fs18px}`}>CLABE: {order?.clabe}</div>
        <div className={`${styles.colorGray} ${styles.fs12px}`}>Debes de pagar</div>
        <div className={`${styles.color212247} ${styles.fs40px}`}>{selectedPrice} MXN</div>
        <div className={`${styles.colorGray} ${styles.fs12px}`}>Paga antes del {order?.limit_date}</div>
        <div className={`${styles.colorGray}`}>Esta informacion tambien fue enviada a tu correo</div>
        <br />
        <Btn className='btnBlueRoundedBig' type={'button'} onClick={()=>{onAllReadySubmit(visible)}}>Continuar</Btn>
      </div>
    </Modal>
  )
}
