import { useContext, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Modal } from '@/components/moleculas/Modal'
import { FormHookProvider } from '@/components/moleculas'
import { Accordion, AccordionTab } from 'primereact/accordion'
// import { InputText } from '@/components/moleculas/InputText'
// import { InputPassword } from '@/components/moleculas/InputPassword'
// import { Button } from '@/components/atoms'
import { useForm } from 'react-hook-form'
import PaymentMethodEngine from '@/components/moleculas/PaymentMethodEngine/PaymentMethodEngine'
import { payMemberAsync, saveCardAsync } from '@/services/axios/authenticated/account/paymentMethod'
// import { useRouter } from 'next/router'
import Btn from '@/components/atoms/Btn/Btn'
import { AuthContext } from '@/context/AuthContext'
import Image from 'next/image'

export default function ModalOxxoReference ({
  visible,
  onAllReadySubmit,
  selectedPrice,
  order,
  onHide = false
}) {
  // console.log('asdf',visible,
  //   onAllReadySubmit,
  //   selectedPrice,
  //   order,
  //   onHide, !!visible);
  // const router = useRouter()
  // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0)
  // const [order, setOrder] = useState(false)

  return (
    <Modal
      visible={!!visible}
      onHide={onHide}
      width='580px'
      dismissableMask
    >
      <div className={styles.payment_header}>
        Referencia de pago
      </div>
      <div className={styles.payment_container} style={{ height: 'bodyHeight' }}>
        <div>¡Tu referencia para pagar en efectivo en <br /> tiendas oxxo esta lista!</div>
        <div className={`${styles.color212247} ${styles.fs18px}`}>Referencia: {order?.reference}</div>
        <div className={`${styles.colorGray} ${styles.fs12px}`}>Debes de pagar</div>
        <div className={`${styles.color212247} ${styles.fs40px}`}>{selectedPrice} MXN</div>
        <div className={`${styles.color212247}`}>+ la comisión que te indiquen en oxxo</div>
        <div className={`${styles.colorGray} ${styles.fs12px}`}>Paga antes del {order?.limit_date}</div>
        <div className={`${styles.colorGray}`}>Tu numero de referencia fue enviado a tu correo</div>
        <br />
        <Image
          src='/img/home/imageOxxo.png'
          alt='Picture of the author '
          className={styles.anuncioimg}
          width={476}
          height={228}
        />
        <Btn className='btnBlueRoundedBig' type={'button'} onClick={()=>{onAllReadySubmit(visible)}}>Continuar</Btn>
      </div>
    </Modal>
  )
}
