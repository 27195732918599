import { InputTextarea as InputTextPrime } from 'primereact/inputtextarea'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { Skeleton } from 'primereact/skeleton'

export const InputTextArea = ({
  name,
  type,
  value,
  onChange,
  placeholder,
  maxLength,
  invalidClassName,
  invalidMessageClassName,
  className,
  styleButton,
  disabled = false,
  rows,
  cols,
  style,
  rules,
  onBlurCaptureFun = () => { },
  onBlur = () => { },
  readOnly,
  onKeyDown,
  onKeyUp,
  loading,
  onClick
}) => {
  const { field, fieldState: { error } } = useController({
    name,
    rules,
    defaultValue: value
  })

  const handleOnChange = useCallback(
    (e) => {
      onChange(e)
      field.onChange(e)
    },
    [field, onChange]
  )

  useEffect(() => {
    if (value) {
      handleOnChange({ target: { value } })
    }
  }, [value])

  return (
    loading
      ? <Skeleton width='100%' height='15vh' />
      : (
        <>
          <InputTextPrime
            value={value}
            {...field}
            name={name}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            className={`${className} ${error && invalidClassName}`}
            style={{ fontSize: '12.5px', ...styleButton }}
            disabled={disabled}
            readOnly={readOnly}
            rows={rows}
            onChange={handleOnChange}
            cols={cols}
            onBlurCapture={() => onBlurCaptureFun()}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onClick={onClick}
          />
          {error && <span className={invalidMessageClassName}> {error.message}</span>}
        </>
        )
  )
}

InputTextArea.defaultProps = {
  onChange: () => { },
  loading: false
}

InputTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func
}
