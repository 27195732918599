import { Dialog as DialogPrime } from 'primereact/dialog'
import PropTypes from 'prop-types'

export const Dialog = ({
  visible,
  onHide,
  dismissableMask,
  closable,
  header,
  footer,
  style,
  breakpoints,
  className,
  children
}) => {
  return (
    <DialogPrime
      visible={visible}
      onHide={onHide}
      dismissableMask={dismissableMask}
      closable={closable}
      header={header}
      footer={footer}
      style={style}
      breakpoints={breakpoints}
      className={className}
    >
      {children}
    </DialogPrime>
  )
}

Dialog.propTypes = {
  className: PropTypes.string
}
