import { InputText as InputTextAtom } from '@/components/atoms/Input'
import styles from './styles.module.scss'

export const InputTextCode = (props) => {
  const { className } = props
  return (
    <InputTextAtom
      {...props}
      maxLength='1'
      invalidClassName={styles.invalid}
      className={`${styles.input_code} ${className}`}
    />
  )
}

InputTextCode.defaultProps = {
  className: ''
}
