import { InputSwitch as InputSwitchPrime } from 'primereact/inputswitch'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

export const InputSwitch = ({
  name = '',
  defaultValue,
  checked,
  onChange,
  rules,
  readOnly,
  disabled,
  invalidClassName,
  className
}) => {
  const { field, fieldState: { error } } = useController({
    name,
    rules,
    defaultValue
  })

  const handleOnChange = (e) => {
    onChange(e)
    field.onChange(e.value)
  }

  useEffect(() => {
    field.onChange(checked)
  }, [checked])

  return (
    <InputSwitchPrime
      {...field}
      name={name}
      inputId={field.name}
      inputRef={field.ref}
      checked={field.value}
      disabled={disabled || readOnly}
      className={`${className} ${error ? invalidClassName : ''}`}
      onChange={(e) => handleOnChange(e)}
    />
  )
}

InputSwitch.defaultProps = {
  onChange: () => { },
  className: ''
}

InputSwitch.propTypes = {
  className: PropTypes.string
}
