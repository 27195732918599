import React, { useEffect } from 'react'
import './PaymentMethodEngine.module.scss'

import { sweetInfo } from '@/utils/sweetalert2'

let instancesCount = 0

const PaymentMethodEngine = ({ repetir, setOpenModalPaymentMethod, onSucced }) => {
  const apiKeyConekta = process.env.NEXT_PUBLIC_KEY_CONEKTA
  // console.log("apiKeyConekta", apiKeyConekta)
  const callbacks = {
    // Evento que permitirá saber que el token se creado de forma satisfactoria, es importante que se consuman los datos que de él derivan.
    onCreateTokenSucceeded: function (token) {
      onSucced(token)
    },
    // Evento que permitirá saber que el token se creado de manera incorrecta, es importante que se consuman los datos que de él derivan y se hagan las correciones pertinentes.
    onCreateTokenError: function (/* error */) {
      sweetInfo('', '¡Al parecer hay un error en los datos, inténtalo de nuevo!')
    }
  }

  useEffect(() => {
    // if (tokenConekta) {
    instancesCount++
    if (instancesCount === 1) {
      // if (!window.ConektaCheckoutComponents) {
      window.ConektaCheckoutComponents.Card({
        config: {
          targetIFrame: '#conektaIframeContainer',
          // checkoutRequestId: tokenConekta, // // Checkout request ID, es el mismo ID generado en el paso 1
          publicKey: apiKeyConekta, // Llaves: https://developers.conekta.com/docs/como-obtener-tus-api-keys
          locale: 'es' // 'es' Español | 'en' Ingles
        },
        callbacks,
        // //Este componente "allowTokenization" permite personalizar el tokenizador, por lo que su valor siempre será TRUE
        allowTokenization: true,
        targetIFrame: '#conektaIframeContainer',
        options: {
          theme: 'green',
          styles: {
            inputType: 'basic',
            button: {
              colorText: '#000',
              text: 'fjfj',
              backgroundColor: '#000'
            }
          }
        }

      })
      // }
    }
    // }
  }, [repetir])

  instancesCount = 0
  return (
    <>
      <div id='conektaIframeContainer' className='modal-pay-method-edit' style={{ height: '468px', width: '100%' }} />
    </>
  )
}
export default PaymentMethodEngine
