import { InputPassword as InputPasswordAtom } from '@/components/atoms'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'

export const InputPassword = (props) => {
  const { className, name, label } = props

  return (
    <div className={`${styles.input} ${className}`}>
      <label htmlFor={name}>{label}</label>
      <InputPasswordAtom {... props} invalidClassName={styles.invalid} />
    </div>
  )
}

InputPassword.propTypes = {
  label: PropTypes.string
}
