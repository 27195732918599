import { FormProvider } from 'react-hook-form'

export const FormHookProvider = ({ methods, method, onSubmit, className, children }) => {
  return (
    <FormProvider {...methods}>
      <div className={className}>
        <form onSubmit={methods.handleSubmit(onSubmit)} on method={method}>
          {children}
        </form>
      </div>
    </FormProvider>
  )
}
