import { InputText as InputTextPrime } from 'primereact/inputtext'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { Skeleton } from 'primereact/skeleton'

export const InputText = ({
  name, // Name of the input element.
  type, //
  value, // Value of the component.
  defaultValue, //
  onChange, //
  onKeyDown, //
  onWheel, //
  onPaste, //
  onFocus,
  onBlur,
  step, //
  min,
  max,
  placeholder, // Default text to display when no input is entered.
  tooltip, // Content of the tooltip.
  tooltipOptions, // Configuration of the tooltip, refer to the tooltip documentation for more information.
  loading, //
  height, //
  rules, // Rules for required input.
  readOnly, // When present, it specifies that the value cannot be changed.
  disabled, // When present, it specifies that the component should be disabled.
  invalidClassName, // Style class for invalid input.
  invalidMessageClassName, //
  className, // Style class of the component.
  style, // Inline style of the element.
  tabIndex,
  autoFocus
}) => {
  const { field, fieldState: { error } } = useController({
    name,
    rules,
    defaultValue
  })

  const handleOnChange = useCallback((e) => {
    onChange(e)
    field.onChange(e)
  }, [field, onChange])

  const handleOnKeyDown = useCallback(
    (e) => {
      onKeyDown(e)
    },
    [onKeyDown]
  )

  useEffect(() => {
    if (value) {
      handleOnChange({ target: { value } })
    } /* else {
      const defaultValue = ''
      handleOnChange({ target: { value: defaultValue } })
    } */
  }, [value])

  return (
    loading
      ? <Skeleton width='100%' height={height} />
      : (
        <>
          <InputTextPrime
            id={field.name}
            name={field.name}
            type={type}
            placeholder={placeholder}
            step={step}
            tooltip={tooltip}
            tooltipOptions={tooltipOptions}
            disabled={disabled}
            readOnly={readOnly}
            className={`${className} ${error && invalidClassName}`}
            style={style}
            value={field.value}
            defaultValue={defaultValue}
            {...field}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onWheel={onWheel}
            onPaste={onPaste}
            onFocus={onFocus}
            onBlur={onBlur}
            min={min}
            max={max}
            tabIndex={tabIndex}
            autoFocus={autoFocus}
          />
          {error && <span className={invalidMessageClassName}> {error.message}</span>}
        </>
        )
  )
}

InputText.defaultProps = {
  name: '',
  type: 'text',
  placeholder: '',
  loading: false,
  readOnly: false,
  disabled: false,
  invalidClassName: '',
  invalidMessageClassName: '',
  className: '',
  value: '',
  onChange: () => { },
  onKeyDown: () => { },
  onPaste: () => { }
}

InputText.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  invalidClassName: PropTypes.string,
  invalidMessageClassName: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func
}
