import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import styles from './styles.module.scss'
import { Button } from '@/components/atoms'
import ModalSelectPlan from '@/components/organisms/ModalSelectPlan/ModalSelectPlan'
import ModalPaymentMethod from '@/components/organisms/ModalPaymentMethod/ModalPaymentMethod'
import ModalPaymentStatus from '@/components/organisms/ModalPaymentStatus/ModalPaymentStatus'
import processing from '@/img/gif/payment_processing.gif'
import accepted from '@/img/gif/payment_accepted.gif'
import declined from '@/img/gif/Payment_declined.gif'
import { AuthContext } from '@/context/AuthContext'

export default function CardMembership ({ membership }) {
  const { onSubmit } = useContext(AuthContext)

  const router = useRouter()
  const [showSelectPlan, setShowSelectPlan] = useState(false)
  const [showPaymentMethod, setShowPaymentMethod] = useState(false)
  const [showPaymentProcessing, setShowPaymentProcessing] = useState(false)
  const [showPaymentAccepted, setShowPaymentAccepted] = useState(false)
  const [showPaymentDeclined, setShowPaymentDeclined] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState({
    promotion: 0,
    total: 0,
    unitPrice: 0
  })

  const handlePayment = () => {
    setShowPaymentProcessing(true)
    setTimeout(() => {
      setShowPaymentProcessing(false)
      setShowPaymentAccepted(true)
    }, 3000)
    setTimeout(() => {
      setShowPaymentAccepted(false)
      setShowPaymentDeclined(true)
    }, 6000)
    setTimeout(() => {
      setShowPaymentDeclined(false)
      router.push('/dashboard')
    }, 9000)
  }

  return (
    <article className={styles.card}>
      <div className={styles.card_header}>
        <h2 className={styles.card_title}>{membership.name}</h2>
        {membership.free
          ? (
            <div className={styles.card_pricing}>
              <div>
                <div className={styles.card_price}>
                  <span>{membership.cost}</span>
                  <span className={styles.card_mxn}>
                    <div>/MXN</div>
                  </span>
                </div>
              </div>
            </div>
            )
          : (
            <div className={styles.card_pricing}>
              <div>
                <p className={styles.cardSubtitle}>Desde</p>
                <div className={styles.card_price}>
                  <span>{membership.cost}</span>
                  <span className={styles.card_mxn}>
                    <div>/MXN</div>
                    <div>{membership.validity}</div>
                  </span>
                </div>
              </div>
              <div className={styles.card_info}>
                <p>Pago anual de {membership.promotion_cost}</p>
                <p>&quot;Ahorra {membership.savings_percentage}%&quot;</p>
              </div>
            </div>
            )}
      </div>
      <div className={styles.card_body} dangerouslySetInnerHTML={{ __html: membership.description }} />
      <div className={styles.card_buttons}>
        {
          membership.free
            ? (
              <div>
                <Button
                  label='Gratis'
                  onClick={(e) => { onSubmit.onFreeMembershipSubmit(membership.id) }}
                  rounded
                  className='btn-yellow btn-xl btn-md-md'
                />
              </div>
              )
            : (
              <div>
                <Button
                  label='Probar'
                  onClick={(e) => { onSubmit.onTryMembershipSubmit(membership.id) }}
                  rounded
                  className='btn-yellow btn-xl btn-md-md'
                />
                <Button
                  label='Comprar'
                  onClick={(e) => { setShowSelectPlan(true) }}
                  rounded
                  className='btn-yellow btn-xl btn-md-md'
                />
              </div>
              )
        }
      </div>
      <div className={styles.card_footer}>
        <p>Costo por usuario extra {membership.additional_user_costs} mxn al mes</p>
      </div>
      <ModalSelectPlan
        visible={showSelectPlan}
        onHide={() => setShowSelectPlan(false)}
        type={membership.name}
        monthlyPrice={membership.cost}
        annualPrice={membership.promotion_cost}
        onClickPay={(data) => {
          setShowPaymentMethod(true)
        }}
        setSelectedPrice={setSelectedPrice}
      />
      <ModalPaymentMethod
        visible={showPaymentMethod}
        onHide={() => setShowPaymentMethod(false)}
        onClickPay={handlePayment}
        selectedPrice={selectedPrice}
        membership={membership.id}
        promotionId={selectedPrice.promotion}
        setShowPaymentProcessing={setShowPaymentProcessing}
        setShowPaymentAccepted={setShowPaymentAccepted}
        setShowPaymentDeclined={setShowPaymentDeclined}
      />
      <ModalPaymentStatus
        visible={showPaymentProcessing}
        onHide={() => setShowPaymentProcessing(false)}
        title='Procesando Pago'
        img={processing}
      />
      <ModalPaymentStatus
        visible={showPaymentAccepted}
        onHide={() => setShowPaymentAccepted(false)}
        title='Pago aceptado'
        img={accepted}
      />
      <ModalPaymentStatus
        visible={showPaymentDeclined}
        onHide={() => setShowPaymentDeclined(false)}
        title='Pago rechazado'
        img={declined}
      />
    </article>
  )
}
