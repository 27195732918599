import { post, get } from '../../api'

export async function saveCardAsync (formData) {
  return await post('conekta/card', formData)
}

export async function deleteCardAsync (id, fun) {
  const formData = { _method: 'delete' }
  return await post(`conekta/card/${id}`, formData, fun)
}

export async function getCardAsync (formData) {
  return await get('conekta/card', formData)
}

export async function payMemberAsync (formData, id) {
  return await post(`user/register/membership/${id}/pay`, formData)
}

export async function setDefaultCard (formData, id) {
  return await post(`conekta/card/default`, formData)
}
