import Image from 'next/image'
import styles from './styles.module.scss'
import { Modal } from '@/components/moleculas/Modal'

export default function ModalPaymentStatus ({ visible, onHide, title, img }) {
  return (
    <Modal
      visible={visible}
      onHide={onHide}
      width='580px'
      dismissableMask
    >
      <div className={styles.element}>
        <div className={styles.element_header}>
          {title}
        </div>
        <div className={styles.element_body}>
          <Image
            src={img}
            alt='processing'
            width={500}
            height={295}
          />
        </div>
      </div>
    </Modal>
  )
}
