import React from 'react'
import styles from './Btn.module.scss'

const Btn = ({ onClick, className, children, type, width, disabled = false, dataTut }) => {
  return (
    <button data-tut={dataTut} disabled={disabled} className={`${styles.btn} ${styles[className]}`} onClick={onClick} type={type} style={{ width }}>
      {children}
    </button>
  )
}
export default Btn
