import { InputNumber as InputNumberAtom } from '@/components/atoms'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'

export const InputNumber = ({
  name,
  value,
  onValueChange,
  placeholder,
  label,
  min,
  max,
  step,
  mode,
  currency,
  prefix,
  suffix,
  showButtons,
  required,
  disabled,
  className
}) => {
  return (
    <div className={styles.input}>
      <label htmlFor={name}>{label}</label>
      <InputNumberAtom
        name={name}
        value={value}
        onValueChange={onValueChange}
        placeholder={placeholder}
        min={min}
        max={max}
        step={step}
        mode={mode}
        currency={currency}
        prefix={prefix}
        suffix={suffix}
        showButtons={showButtons}
        required={required}
        disabled={disabled}
        invalidClassName={styles.invalid}
        className={className}
      />
    </div>
  )
}

InputNumber.propTypes = {
  label: PropTypes.string
}
