import { Password } from 'primereact/password'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'

export const InputPassword = ({
  name,
  value,
  onChange,
  placeholder,
  feedback,
  toggleMask,
  rules,
  disabled,
  invalidClassName,
  className
}) => {
  const { field, fieldState: { error } } = useController({
    name,
    rules,
    defaultValue: value
  })

  return (
    <>
      <Password
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        feedback={feedback}
        className={`${className} ${error && invalidClassName}`}
        toggleMask={toggleMask}
        disabled={disabled}
        {...field}
      />
      {error && <span> {error.message}</span>}
    </>
  )
}

InputPassword.defaultProps = {
  name: '',
  placeholder: '',
  value: '',
  required: false
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired
}
