import { InputSwitch as InputSwitchAtom } from '@/components/atoms'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'

export const InputSwitch = ({
  name,
  defaultValue,
  checked,
  onChange,
  label,
  rules,
  readOnly,
  disabled,
  className
}) => {
  return (
    <div className={`${styles.inputswitch} ${className}`}>
      <label htmlFor={name}>{label}</label>
      <InputSwitchAtom
        name={name}
        defaultValue={defaultValue}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        rules={rules}
        invalidClassName={styles.invalid}
        className={`${readOnly ? styles.readonly : ''} ${disabled ? styles.disabled : ''}`}
      />
    </div>
  )
}

InputSwitch.propTypes = {
  label: PropTypes.string
}
