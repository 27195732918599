import { InputNumber as InputNumberPrime } from 'primereact/inputnumber'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'

export const InputNumber = ({
  name,
  value,
  onValueChange,
  placeholder,
  min,
  max,
  step,
  mode,
  currency,
  prefix,
  suffix,
  showButtons,
  required,
  disabled,
  invalidClassName,
  className
}) => {
  const { field, fieldState: { error } } = useController({
    name,
    rules: { required },
    defaultValue: value
  })

  return (
    <InputNumberPrime
      name={name}
      value={value}
      onValueChange={onValueChange}
      placeholder={placeholder}
      min={min}
      max={max}
      step={step}
      mode={mode}
      currency={currency}
      prefix={prefix}
      suffix={suffix}
      showButtons={showButtons}
      disabled={disabled}
      className={`${className} ${error && invalidClassName}`}
      {...field}
    />
  )
}

InputNumber.propTypes = {
  name: PropTypes.string.isRequired
}
